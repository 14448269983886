<div class="main-content">
  <div class="content-space">
    <form #contactUsForm="ngForm">

      <div class="content-header">{{ "ContactUs1" | localize }}</div>
      <div class="row">

        <!-- Title -->
        <mat-form-field class="col-xl-1 col-lg-2 col-sm-2">
          <mat-label>{{ "Title" | localize }}</mat-label>
          <mat-select name="title" [(ngModel)]="newMessage.client.title" required>
            <mat-option [value]="'Mr' | localize">{{ "Mr" | localize }}</mat-option>
            <mat-option [value]="'Mrs' | localize">{{ "Mrs" | localize }}</mat-option>
            <mat-option [value]="'Miss' | localize">{{ "Miss" | localize }}</mat-option>
            <mat-option [value]="'Ms' | localize">{{ "Ms" | localize }}</mat-option>
            <mat-option [value]="'Other' | localize">{{ "Other" | localize }}</mat-option>
            <mat-option [value]="'PreferNotToSay' | localize">{{ "PreferNotToSay" | localize }}</mat-option>
          </mat-select>
        </mat-form-field>

        <!-- Name -->
        <mat-form-field class="col-xl-5 col-lg-4 col-sm-10">
          <mat-label>{{ "FirstName" | localize }}</mat-label>
          <input matInput name="name" [(ngModel)]="newMessage.client.name" required maxlength="30">
        </mat-form-field>

        <!-- Surname -->
        <mat-form-field class="col-xl-6 col-lg-6 col-sm-12">
          <mat-label>{{ "Surname" | localize }}</mat-label>
          <input matInput name="surname" [(ngModel)]="newMessage.client.surname" required maxlength="30">
        </mat-form-field>

        <!-- Email -->
        <mat-form-field class="col-xl-6 col-lg-6 col-sm-12">
          <mat-label>{{ "EmailAddress" | localize }}</mat-label>
          <input matInput
                 name="email"
                 [(ngModel)]="newMessage.client.email"
                 required
                 maxlength="30"
                 type="email"
                 pattern="^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{1,})+$"
          >
        </mat-form-field>

        <!-- Phone -->
        <mat-form-field class="col-xl-6 col-lg-6 col-sm-12">
          <ngx-mat-intl-tel-input
              [preferredCountries]="['gb']"
              [enablePlaceholder]="true"
              [enableSearch]="true"
              [(ngModel)]="newMessage.client.phone"
              name="phone"
              #phone
          ></ngx-mat-intl-tel-input>
          <mat-hint>e.g. {{ phone.selectedCountry.placeHolder }}</mat-hint>
          <mat-error *ngIf="contactUsForm.form.controls['phone']?.errors?.required">
            {{ "RequiredField" | localize }}
          </mat-error>
          <mat-error *ngIf="contactUsForm.form.controls['phone']?.errors?.validatePhoneNumber">
            {{ "InvalidNumber" | localize }}
          </mat-error>
        </mat-form-field>

        <!-- Subject -->
        <mat-form-field class="col-xl-6 col-lg-6 col-sm-12">
          <mat-label>{{ "Organisation" | localize }}</mat-label>
          <input matInput name="subject" [(ngModel)]="newMessage.client.organisation" maxlength="50">
        </mat-form-field>

        <!-- Message -->
        <mat-form-field>
          <mat-label>{{ "Message" | localize }}</mat-label>
          <textarea matInput name="message" [rows]="5" [(ngModel)]="newMessage.message" required maxlength="300"></textarea>
        </mat-form-field>

        <!-- isEmailSubscriptionConfirmed-->
        <mat-checkbox ngModel [(ngModel)]="newMessage.client.isEmailSubscriptionConfirmed"
                      name="isEmailSubscriptionConfirmed">
          {{ 'OptInToReceiveCommunicationsFromOptimisareToYourInbox' | localize }}
        </mat-checkbox>

      </div>

      <!-- Интеграция ngx-captcha -->
      <ngx-recaptcha2 [siteKey]="siteKey" (success)="handleCaptchaResponse($event)"></ngx-recaptcha2>

      <button mat-raised-button type="submit" class="btn btn-toolbar pull-right" style="margin-left: 20px;" (click)="submit()">
        {{ "Submit" | localize }}
      </button>
    </form>
  </div>
</div>
